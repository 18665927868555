/**
 * Hide the Hubspot support chat
 *
 */
export const hideSupportChat = (): void => {
  addClass("support-chat-widget-hide");
};

/**
 * Show the Hubspot support chat again (after hiding)
 *
 */
export const showSupportChat = (): void => {
  removeClass("support-chat-widget-hide");
};

/**
 * Add a predefined class to the DOM
 *
 * @param className name of the class to add
 */
export const addClass = (className: string): void => {
  document.body.classList.add(className);
};

/**
 * Remove a predefined class to the DOM
 *
 * @param className name of the class to remove
 */
export const removeClass = (className: string): void => {
  document.body.classList.remove(className);
};
