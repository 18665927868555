import "reflect-metadata";
import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { VueFire, VueFireAuth } from "vuefire";
import { abilitiesPlugin } from "@casl/vue";
// the file we created above with `database`, `firestore` and other exports
import { firebaseApp } from "./firebase";
import App from "./App.vue";
import { i18n } from "./i18n";
import { ab } from "./services/casl";
import * as Sentry from "@sentry/vue";
import { hideSupportChat } from "./utils/dom-functions";

import "./style.css";
import { ErrorContext, ErrorType, captureError } from "./utils/errors";
import { useAnalytics } from "./composables/use-analytics";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const app = createApp(App);
app.use(i18n);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(router);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  enabled: process.env.NODE_ENV !== "development",
  app,
});

app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()],
});

app.use(pinia);

app.config.errorHandler = (err) => {
  captureError(err, ErrorContext.OOPS, ErrorType.SOMETHING_WENT_WRONG);
};

app.use(abilitiesPlugin, ab, { useGlobalProperties: true });

app.mount("#app");

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smallerOrEqual("sm");
if (isMobile.value) {
  hideSupportChat();
}

useAnalytics().initializeMixpanel();
