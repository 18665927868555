export const SUPERUSER_IDS = [
  // production - log-and-solve
  "FHKkMJX12dZ4krDBjRFH0ed3yYi1", // co-anne.vanwaaij@klasmann-deilmann.com
  "Pmubczu9eXXyeizHwJzuDxzlYkm2", // d.smits@30mhz.com
  "WOzkEQqEbtQCh693PuBky1yriFC2", // f.mikaelian@30mhz.com
  "Onpuei1jO8aLLlLLB8NADoz4aMX2", // gerard.flinterman@klasmann-deilmann.com
  "6N8H0H64AmUcVryYlF8Ypcc4AaD3", // m.froberg@30mhz.com
  "fdbQscgk8XP6JkeUOAIcI1HEgUx2", // rick.vanmarrewijk@klasmann-deilmann.com
  "IBl6txAUeAgLzw6FpAVUqgR7r3r2", // s.vrielink@30mhz.com
  "rGsYZXd2xoOuJGzrW40JWfpiIfL2", // tess.vollebregt@klasmann-deilmann.com
  // staging - log-and-solve-staging-7a93b
  "7ISRss7jtPUsRJYZzqLYLzzgY3D3", // d.smits@30mhz.com
  "A0RmuUW5ltM4c9KlujyZnmOCSGf2", // f.mikaelian@30mhz.com
  "UCTQujLlwqXsKrgnHQOquIopsB03", // m.froberg@30mhz.com
];
